<template>
  
  <a-layout class="layout">
    
    <a-layout-header style="background: #7dbcea; color: white; text-align: center;">
      <h1>代理IP格式转换工具</h1>
    </a-layout-header>
    <a-layout-content style="padding: 24px; max-width: 1200px; margin: auto;">

      <a-row :gutter="16">
        <a-col :span="24" :md="11" :lg="11">
          <a-textarea v-model="inputText" :rows=10 placeholder="输入文本" />
          <a-select v-model="inputFormat" style="width: 100%; margin-top: 10px;">
            <a-select-option value="ikun_in" aria-selected="">ikun格式</a-select-option>
            <a-select-option value="wls_in">ip|端口|账号|密码|过期时间</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="11" :lg="11">
          <a-textarea v-model="outputText" :rows=10 placeholder="输出文本"  />
          <!-- readonly -->
          <a-select v-model="outputFormat" style="width: 100%; margin-top: 10px;">
            <a-select-option value="wls_out">ip|端口|账号|密码|过期时间</a-select-option>
            <a-select-option value="http_ikun">http-ikun格式</a-select-option>
            <a-select-option value="socks5_ikun">socks5-ikun格式</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-button type="primary" block style="margin-top: 20px;" @click="convertText">
        转换格式
      </a-button>
    </a-layout-content>
    <a-layout-footer style="text-align: center;">
      Ant Design ©2024 Created by Mr.she
    </a-layout-footer>
  </a-layout>
  
</template>

<script>
export default {
  data() {
    return {
      inputText: '',
      outputText: '',
      inputFormat: 'wls_in',
      outputFormat: 'http_ikun',
      standardData: []
    };
  },mounted() {
    document.title = 'IP CONVERSION TOOL';
  },
  methods: {
    convertText() {
      // 这里应该包含转换逻辑
      if (!this.inputFormat || !this.outputFormat || !this.inputText){
        console.log()
      }
      // console.log(this.getInput(this.inputText))
      // console.log(this.inputText)
      this.standardData = this.getInput()
      const out = this.getOutput()
      // console.log('out', out)
      this.outputText = out;
    },
    getInput() {
      const data = this.inputText
      const inType = this.inputFormat
      if (inType == 'ikun_in'){
        const lines = data.split('\n');
        // 过滤空白行并映射到对象数组
        const proxies = lines.filter(line => line.trim() !== '').map(line => {
            // 按 '|' 分割每行数据
            const dd = line.split('//')[1];
            const parts = dd.split('@');
            const u = parts[0].split(':');
            const i = parts[1].split(':');
            // 构建并返回代理信息的字典
            return {
                ip: i[0],
                port: i[1],
                username: u[0],
                password: u[1],
                expiryDate: 'unkwoun'
            };
        });
        return proxies;
      }else if(inType == 'wls_in'){
        const lines = data.split('\n');
        // 过滤空白行并映射到对象数组
        const proxies = lines.filter(line => line.trim() !== '').map(line => {
            // 按 '|' 分割每行数据
            const parts = line.split('|');
            // 构建并返回代理信息的字典
            return {
                ip: parts[0],
                port: parts[1],
                username: parts[2],
                password: parts[3],
                expiryDate: parts[4]
            };
        });
        return proxies;
      }
      
    },
    getOutput() {
      const data = this.standardData
      const outType = this.outputFormat
      let output = ''
      // ip|端口|账号|密码|过期时间
      if (outType == 'wls_out'){
        data.forEach(item => {
          output += `${item.ip}|${item.port}|${item.username}|${item.password}|${item.expiryDate}` + "\n";
        });
      }else if(outType == 'http_ikun'){
        data.forEach(item => {
          output += `http://${item.username}:${item.password}@${item.ip}|${item.port}` + "\n";
          // console.log(output)
        });
      }else if(outType == 'socks5_ikun'){
        data.forEach(item => {
          output += `socks5://${item.username}:${item.password}@${item.ip}|${item.port}` + "\n";
        });
      }
      return output
    }
  }
};
</script>

<style>
.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

html, body {
  height: 100%;
  margin: 0;
}

</style>
