import Vue from 'vue';
import App from './App.vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css'; // 引入样式文件

Vue.config.productionTip = false;
Vue.use(Antd); // 使用 Antd

new Vue({
  render: h => h(App),
}).$mount('#app');
